<template>
    <div class="form-page" >
        <div class="page-search">
            <input type="text" class="page" placeholder="Поиск по разделам CRM" v-model="search.searchCheckbox">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3138 12.0596H12.6555L12.4221 11.8346C13.4221 10.668 13.9388 9.07631 13.6555 7.38464C13.2638 5.06798 11.3305 3.21798 8.99713 2.93464C5.47213 2.50131 2.50546 5.46798 2.93879 8.99298C3.22213 11.3263 5.07213 13.2596 7.38879 13.6513C9.08046 13.9346 10.6721 13.418 11.8388 12.418L12.0638 12.6513V13.3096L15.6055 16.8513C15.9471 17.193 16.5055 17.193 16.8471 16.8513C17.1888 16.5096 17.1888 15.9513 16.8471 15.6096L13.3138 12.0596ZM8.31379 12.0596C6.23879 12.0596 4.56379 10.3846 4.56379 8.30964C4.56379 6.23464 6.23879 4.55964 8.31379 4.55964C10.3888 4.55964 12.0638 6.23464 12.0638 8.30964C12.0638 10.3846 10.3888 12.0596 8.31379 12.0596Z" fill="#656565"/>
            </svg>
        </div>
        <div class="crm-category-wrap category-chk">

            <simplebar data-simplebar-auto-hide="false" ref="simplebar" style="max-height: 436px; width: 100%;">
            <div class="crm-category-checkbox" v-for="itemCheckbox in filterCheckbox" :key="itemCheckbox.type_id">
                <input type="checkbox"
                       :name="itemCheckbox.type_id"
                       :id="itemCheckbox.type_id"
                       :checked="this.selectedEntitys.includes(String(itemCheckbox.type_id)) ? true : false"
                >
                <label :for="itemCheckbox.type_id">{{ itemCheckbox.title }}</label>
            </div>
            </simplebar>

        </div>
    </div>
</template>

<script>
import axios from "axios"
import simplebar from "simplebar-vue"
import 'simplebar-vue/dist/simplebar.min.css'

export default {
    name: "CategoryCrm",
    components: {
        simplebar
    },
    data() {
        return {
            arrCheckBox: [],
            search: {
                searchCheckbox: ''
            },
            getId: localStorage.getItem('id'),
            selectedEntitys: [],
            //checked: ,
            //checkedArr: []
        }
    },
    methods: {

    },
    computed: {
        filterCheckbox:function () {
            return this.arrCheckBox.filter(item => {
                return item.title.toLowerCase().indexOf(this.search.searchCheckbox.toLowerCase()) !== -1
            })
        },
    },
    mounted() {
        axios
            .get("https://sibteh.space/form_constructor_demo/api/b24/get_entitys/")
            .then((response) => {
                this.arrCheckBox = response.data.result
                //console.log(response.data.result)
            })
            .catch((error) => {
                console.log(error)
            })


        if (this.getId > 0) {
            axios
                .get("https://sibteh.space/form_constructor_demo/api/form/load/?id=" + this.getId)
                .then((response) => {
                    this.selectedEntitys = response.data.result.selectedEntitys
                    console.log(response.data.result.selectedEntitys)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}
</script>

<style scoped>
.page-search {
    position: relative;

    & svg {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 16px auto 0;
        cursor: pointer;
    }

    & input[type="text"].page {
        display: flex;
        padding: 0 16px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #c0c0c0;
        background: #fff;
        border-radius: 10px;
    }
}
.crm-category-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin-top: 5px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}
.crm-category-checkbox {
    display: flex;
    align-items: center;
    width: calc(50% - 16px);

    &:first-child {
        margin-left: -12px !important;
    }

    & label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #656565;
    }

    & {
        input[type="checkbox"]:checked,
        input[type="checkbox"]:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        input[type="checkbox"]:checked + label,
        input[type="checkbox"]:not(:checked) + label {
            display: inline-block;
            position: relative;
            padding-left: 28px;
            line-height: 20px;
            cursor: pointer;
        }

        input[type="checkbox"]:checked + label:before,
        input[type="checkbox"]:not(:checked) + label::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 16px;
            height: 16px;
            background-color: transparent;
            border: 1px solid #656565;
        }

        input[type="checkbox"]:checked + label:before,
        input[type="checkbox"]:not(:checked) + label:before {
            border-radius: 4px;
        }

        input[type="checkbox"]:checked + label:after,
        input[type="checkbox"]:not(:checked) + label:after {
            content: "";
            position: absolute;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        input[type="checkbox"]:checked + label:after,
        input[type="checkbox"]:not(:checked) + label:after {
            left: 1px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 14px;
            height: 14px;
            background: url("../img/check2.svg") 50% 50% no-repeat;
        }

        input[type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
        }

        input[type="checkbox"]:checked + label:after {
            opacity: 1;
        }
    }

}
</style>