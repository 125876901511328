import Home from "@/pages/Home.vue";
import Settings from "@/pages/Settings.vue";
import {createRouter, createWebHistory} from "vue-router";


const routes = [
  {
    path: '/',
    component: Home,
    //meta: { transition: 'fade'},
  },
  {
    path: '/settings/',
    component: Settings,
    //meta: { transition: 'fade' },
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL)
})

export default router