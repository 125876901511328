<template>
    <div class="form-page">
        <div class="form-page__item">
            <label for="">Название формы</label>
            <input type="text"
                   :placeholder="getId !== '0' ? formSettings.formName : 'Форма регистрации участников'"
            >
        </div>

        <div class="form-page__item">
            <label for="">Cообщение после успешной отправки формы</label>
            <input type="text"
                   :placeholder="getId !== '0' ? formSettings.successMessage : 'Информация успешно отправлена!'"
            >
        </div>
        <div class="form-page__item">
            <label for="">Cообщение после неуспешной отправки формы</label>
            <input type="text"
                   :placeholder="getId !== '0' ? formSettings.errorMessage : 'Информация успешно не отправлена!'"

            >
        </div>
        <div class="form-page__item">
            <label for="">Ссылка на страницу соглашения</label>
            <input type="text"
                   :placeholder="getId !== '0' ? formSettings.rulesLink : 'www.design.com'"
            >
        </div>

        <div class="form-page__item">
            <label for="">Файл соглашения</label>
            <label class="input-file">
                <input type="file" name="file" @change="previewFiles">
                <span class="input-file-text">{{ inputFileTitle }}</span>
                <span class="input-file-btn">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.3334 4.99998V14.5833C13.3334 16.425 11.8417 17.9166 10.0001 17.9166C8.15841 17.9166 6.66675 16.425 6.66675 14.5833V4.16665C6.66675 3.01665 7.60008 2.08331 8.75008 2.08331C9.90008 2.08331 10.8334 3.01665 10.8334 4.16665V12.9166C10.8334 13.375 10.4584 13.75 10.0001 13.75C9.54175 13.75 9.16675 13.375 9.16675 12.9166V4.99998H7.91675V12.9166C7.91675 14.0666 8.85008 15 10.0001 15C11.1501 15 12.0834 14.0666 12.0834 12.9166V4.16665C12.0834 2.32498 10.5917 0.833313 8.75008 0.833313C6.90841 0.833313 5.41675 2.32498 5.41675 4.16665V14.5833C5.41675 17.1166 7.46675 19.1666 10.0001 19.1666C12.5334 19.1666 14.5834 17.1166 14.5834 14.5833V4.99998H13.3334Z" fill="#656565"/>
                    </svg>
                </span>
            </label>
        </div>
    </div>
</template>

<script>
//import BaseInput from "@/components/BaseInput.vue";
import axios from "axios";

export default {
    name: "FirstSettings",
    components: {
        // BaseInput,
    },
    data() {
        return {
            inputFileTitle: 'имя_файла_по_умолчанию.pdf',
            inputText1: '',
            inputText2: '',
            inputText3: '',
            inputText4: '',
            getId: localStorage.getItem('id'),
            form: [],
            formSettings: []
        }
    },
    methods: {
        previewFiles(event) {
            this.inputFileTitle = event.target.files[0].name
        },
    },
    mounted() {
        if (this.getId > 0) {
            axios
                .get("https://sibteh.space/form_constructor_demo/api/form/load/?id=" + this.getId)
                .then((response) => {
                    this.form = response.data.result
                    if (response.data.result.formSettings.length !== 0) {
                        this.formSettings = response.data.result.formSettings
                    } else {
                        this.formSettings = {
                            formName: '',
                            successMessage: '',
                            errorMessage: '',
                            rulesLink: ''
                        }
                    }

                    console.log(response.data.result)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}
</script>

<style scoped>

</style>