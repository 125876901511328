<template>
    <div class="form-page">
        <div class="form-page__item">
            <label for="">еще что то</label>
            <input type="text">
        </div>
        <div class="form-page__item">
            <label for="">тут тоже компоненты</label>
            <input type="text">
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Design"
}
</script>

<style scoped>

</style>