<template>

    <div class="form-page">

        <div class="form-checkbox">
            <input type="checkbox" name="" id="1"
                v-model="simple"
            >
            <label for="1">
                <div class="checkbox-box">
                    <div class="title-checkbox">Простая</div>
                    <span>Форма позволяет создать по одному элементу заданных сущностей.</span>
                </div>
            </label>
        </div>

        <div class="form-checkbox">
            <input type="checkbox" name="" id="2">
            <label for="2">
                <div class="checkbox-box">
                    <div class="title-checkbox">Сложная</div>
                    <span>Форма позволяет создать несколько элементов заданных сущностей, количество элементов может быть неизвестно.</span>
                </div>
            </label>
        </div>

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "TypeForm",
    data() {
        return {
            arrCheckBox: [],
            getId: localStorage.getItem('id'),
            formSettings: [],
            simple: false
        }
    },
    methods: {
        checkedBox() {
            console.log(this.arrCheckBox)
        }
    },
    mounted() {
        if (this.getId > 0) {
            axios
                .get("https://sibteh.space/form_constructor_demo/api/form/load/?id=" + this.getId)
                .then((response) => {
                    this.form = response.data.result
                    if (response.data.result.formSettings.length !== 0) {
                        this.formSettings = response.data.result.formSettings
                        if (response.data.result.formSettings.formType === 'simple') {
                            this.simple = true
                        }
                    } else {
                        this.formSettings = {
                            formType: ''
                        }
                    }

                    //console.log(response.data.result)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}
</script>

<style scoped>
.form-checkbox {
    display: flex;
    align-items: center;

    & .checkbox-box {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-left: 26px;

        & .title-checkbox {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #000;
        }

        & span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #212121;
        }
    }

    & {
        input[type="checkbox"]:checked,
        input[type="checkbox"]:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        input[type="checkbox"]:checked + label,
        input[type="checkbox"]:not(:checked) + label {
            display: inline-block;
            position: relative;
            padding-left: 28px;
            line-height: 20px;
            cursor: pointer;
        }

        input[type="checkbox"]:checked + label:before,
        input[type="checkbox"]:not(:checked) + label::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 24px;
            height: 24px;
            background-color: transparent;
            border: 1px solid #656565;
        }

        input[type="checkbox"]:checked + label:before,
        input[type="checkbox"]:not(:checked) + label:before {
            border-radius: 4px;
        }

        input[type="checkbox"]:checked + label:after,
        input[type="checkbox"]:not(:checked) + label:after {
            content: "";
            position: absolute;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        input[type="checkbox"]:checked + label:after,
        input[type="checkbox"]:not(:checked) + label:after {
            left: 4px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 16px;
            height: 16px;
            background: url("../img/check.svg") 50% 50% no-repeat;
        }

        input[type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
        }

        input[type="checkbox"]:checked + label:after {
            opacity: 1;
        }
    }
}
</style>