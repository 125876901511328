<template>

    <div class="form-page">

        <!-- paginav -->
        <div class="paginav-block">
            <div class="page-active"
                v-on:click="ulVisible = !ulVisible"
            >
                <span :class="{'link-active': ulVisible}">{{ 'Страница ' + pageNameNumber }}</span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                     :class="{'svg-rotate': ulVisible}"
                >
                    <path d="M10.9142 4.78888C10.86 4.73421 10.7955 4.69081 10.7244 4.6612C10.6533 4.63158 10.5771 4.61633 10.5001 4.61633C10.423 4.61633 10.3468 4.63158 10.2757 4.6612C10.2046 4.69081 10.1401 4.73421 10.0859 4.78888L7.41422 7.46055C7.35999 7.51522 7.29548 7.55862 7.22439 7.58824C7.15331 7.61785 7.07706 7.6331 7.00006 7.6331C6.92305 7.6331 6.8468 7.61785 6.77572 7.58824C6.70463 7.55862 6.64012 7.51522 6.58589 7.46055L3.91422 4.78888C3.85999 4.73421 3.79548 4.69081 3.72439 4.6612C3.65331 4.63158 3.57706 4.61633 3.50006 4.61633C3.42305 4.61633 3.3468 4.63158 3.27572 4.6612C3.20463 4.69081 3.14012 4.73421 3.08589 4.78888C2.97724 4.89818 2.91626 5.04602 2.91626 5.20013C2.91626 5.35424 2.97724 5.50209 3.08589 5.61138L5.76339 8.28888C6.09151 8.6166 6.5363 8.80068 7.00006 8.80068C7.46381 8.80068 7.9086 8.6166 8.23672 8.28888L10.9142 5.61138C11.0229 5.50209 11.0839 5.35424 11.0839 5.20013C11.0839 5.04602 11.0229 4.89818 10.9142 4.78888Z" fill="#3EA748"/>
                </svg>
            </div>
            <ul class="page-ul"
                :class="{'ul-active': ulVisible}"
            >
                <li class="page-li"
                    v-for="(page, i) in pages" :key="i"
                    v-on:click="pageNext(i)"
                    :class="{'li-active': (i + +1) === pageNameNumber}"
                >
                    {{ 'Страница ' + (i + +1) }}
                </li>
            </ul>
        </div>
        <!-- /paginav -->

        <!-- block fields -->
        <div class="fields-list content-list" v-for="(page, i) in pages" :key="i" v-show="pageCount === i">

                <simplebar data-simplebar-auto-hide="false" ref="simplebar" style="max-height: 489px;">
                    <transition-group name="fade">
                    <div class="box-field" v-for="(field, item) in page" :key="item">
                        <div class="top-field">
                            <!-- item -->
<!--                            <span>{{item}}</span>-->
                            <!-- /item -->
                            <span>{{ field.title }}</span>
                            <span>|</span>
                            <span>{{ field.entityName }}</span>
                            <span>|</span>
                            <span>{{ field.type }}</span>
                        </div>
                        <div class="field-block2">
                            <div class="field-item">
                                {{ field.customPlaceholder }}
                            </div>
                            <div class="icon-set">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="icon-path" d="M13 8.00004C13 7.84671 12.9934 7.70004 12.98 7.54671L14.22 6.60671C14.4867 6.40671 14.56 6.03337 14.3934 5.74004L13.1467 3.58671C12.98 3.29337 12.62 3.17337 12.3134 3.30671L10.88 3.91337C10.6334 3.74004 10.3734 3.58671 10.1 3.46004L9.90669 1.92004C9.86669 1.58671 9.58002 1.33337 9.24669 1.33337H6.76002C6.42002 1.33337 6.13335 1.58671 6.09335 1.92004L5.90002 3.46004C5.62669 3.58671 5.36669 3.74004 5.12002 3.91337L3.68669 3.30671C3.38002 3.17337 3.02002 3.29337 2.85335 3.58671L1.60669 5.74671C1.44002 6.04004 1.51335 6.40671 1.78002 6.61337L3.02002 7.55337C3.00669 7.70004 3.00002 7.84671 3.00002 8.00004C3.00002 8.15337 3.00669 8.30004 3.02002 8.45337L1.78002 9.39337C1.51335 9.59337 1.44002 9.96671 1.60669 10.26L2.85335 12.4134C3.02002 12.7067 3.38002 12.8267 3.68669 12.6934L5.12002 12.0867C5.36669 12.26 5.62669 12.4134 5.90002 12.54L6.09335 14.08C6.13335 14.4134 6.42002 14.6667 6.75335 14.6667H9.24002C9.57335 14.6667 9.86002 14.4134 9.90002 14.08L10.0934 12.54C10.3667 12.4134 10.6267 12.26 10.8734 12.0867L12.3067 12.6934C12.6134 12.8267 12.9734 12.7067 13.14 12.4134L14.3867 10.26C14.5534 9.96671 14.48 9.60004 14.2134 9.39337L12.9734 8.45337C12.9934 8.30004 13 8.15337 13 8.00004ZM8.02669 10.3334C6.74002 10.3334 5.69335 9.28671 5.69335 8.00004C5.69335 6.71337 6.74002 5.66671 8.02669 5.66671C9.31335 5.66671 10.36 6.71337 10.36 8.00004C10.36 9.28671 9.31335 10.3334 8.02669 10.3334Z" fill="#656565"/>
                                </svg>
                            </div>
                            <div class="icon-up" @click="() => upSelect(i, item, field)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="icon-path" d="M3.52653 9.86091C3.5885 9.92339 3.66223 9.97299 3.74347 10.0068C3.82471 10.0407 3.91185 10.0581 3.99986 10.0581C4.08787 10.0581 4.175 10.0407 4.25624 10.0068C4.33748 9.97299 4.41122 9.92339 4.47319 9.86091L7.52652 6.80757C7.5885 6.74509 7.66223 6.69549 7.74347 6.66165C7.82471 6.6278 7.91185 6.61037 7.99986 6.61037C8.08787 6.61037 8.175 6.6278 8.25624 6.66165C8.33748 6.69549 8.41122 6.74509 8.47319 6.80757L11.5265 9.86091C11.5885 9.92339 11.6622 9.97299 11.7435 10.0068C11.8247 10.0407 11.9119 10.0581 11.9999 10.0581C12.0879 10.0581 12.175 10.0407 12.2562 10.0068C12.3375 9.97299 12.4112 9.92339 12.4732 9.86091C12.5974 9.736 12.6671 9.56703 12.6671 9.39091C12.6671 9.21478 12.5974 9.04581 12.4732 8.92091L9.41319 5.86091C9.03819 5.48637 8.52986 5.276 7.99986 5.276C7.46986 5.276 6.96153 5.48637 6.58652 5.86091L3.52653 8.92091C3.40236 9.04582 3.33266 9.21478 3.33266 9.39091C3.33266 9.56703 3.40236 9.736 3.52653 9.86091Z" fill="#656565"/>
                                </svg>
                            </div>


                            <div class="icon-del" @click="() => onDel(i, item)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_581_1143)">
                                        <path class="icon-path" d="M14 2.66667H11.9333C11.7786 1.91428 11.3692 1.23823 10.7741 0.752479C10.1791 0.266727 9.43478 0.000969683 8.66665 0L7.33331 0C6.56518 0.000969683 5.82086 0.266727 5.22581 0.752479C4.63077 1.23823 4.22138 1.91428 4.06665 2.66667H1.99998C1.82317 2.66667 1.6536 2.7369 1.52858 2.86193C1.40355 2.98695 1.33331 3.15652 1.33331 3.33333C1.33331 3.51014 1.40355 3.67971 1.52858 3.80474C1.6536 3.92976 1.82317 4 1.99998 4H2.66665V12.6667C2.66771 13.5504 3.01923 14.3976 3.64413 15.0225C4.26902 15.6474 5.11625 15.9989 5.99998 16H9.99998C10.8837 15.9989 11.7309 15.6474 12.3558 15.0225C12.9807 14.3976 13.3323 13.5504 13.3333 12.6667V4H14C14.1768 4 14.3464 3.92976 14.4714 3.80474C14.5964 3.67971 14.6666 3.51014 14.6666 3.33333C14.6666 3.15652 14.5964 2.98695 14.4714 2.86193C14.3464 2.7369 14.1768 2.66667 14 2.66667ZM7.33331 1.33333H8.66665C9.08016 1.33384 9.4834 1.46225 9.82106 1.70096C10.1587 1.93967 10.4143 2.27699 10.5526 2.66667H5.44731C5.5857 2.27699 5.84125 1.93967 6.17891 1.70096C6.51656 1.46225 6.9198 1.33384 7.33331 1.33333ZM12 12.6667C12 13.1971 11.7893 13.7058 11.4142 14.0809C11.0391 14.456 10.5304 14.6667 9.99998 14.6667H5.99998C5.46955 14.6667 4.96084 14.456 4.58577 14.0809C4.21069 13.7058 3.99998 13.1971 3.99998 12.6667V4H12V12.6667Z" fill="#656565"/>
                                        <path class="icon-path" d="M6.66667 12C6.84348 12 7.01305 11.9297 7.13807 11.8047C7.2631 11.6797 7.33333 11.5101 7.33333 11.3333V7.33329C7.33333 7.15648 7.2631 6.98691 7.13807 6.86189C7.01305 6.73686 6.84348 6.66663 6.66667 6.66663C6.48986 6.66663 6.32029 6.73686 6.19526 6.86189C6.07024 6.98691 6 7.15648 6 7.33329V11.3333C6 11.5101 6.07024 11.6797 6.19526 11.8047C6.32029 11.9297 6.48986 12 6.66667 12Z" fill="#656565"/>
                                        <path class="icon-path" d="M9.33335 12C9.51017 12 9.67973 11.9297 9.80476 11.8047C9.92978 11.6797 10 11.5101 10 11.3333V7.33329C10 7.15648 9.92978 6.98691 9.80476 6.86189C9.67973 6.73686 9.51017 6.66663 9.33335 6.66663C9.15654 6.66663 8.98697 6.73686 8.86195 6.86189C8.73693 6.98691 8.66669 7.15648 8.66669 7.33329V11.3333C8.66669 11.5101 8.73693 11.6797 8.86195 11.8047C8.98697 11.9297 9.15654 12 9.33335 12Z" fill="#656565"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_581_1143">
                                            <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </simplebar>


        </div>
        <!-- /block fields -->


        <div class="add-area" v-on:click="showModal">
            <span>Добавить поле</span>
            <div class="add-area-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_113_4747)">
                        <path d="M15.3333 7.33333H8.66667V0.666667C8.66667 0.489856 8.59643 0.320286 8.47141 0.195262C8.34638 0.0702379 8.17681 0 8 0C7.82319 0 7.65362 0.0702379 7.5286 0.195262C7.40357 0.320286 7.33333 0.489856 7.33333 0.666667V7.33333H0.666667C0.489856 7.33333 0.320286 7.40357 0.195262 7.5286C0.0702379 7.65362 0 7.82319 0 8C0 8.17681 0.0702379 8.34638 0.195262 8.47141C0.320286 8.59643 0.489856 8.66667 0.666667 8.66667H7.33333V15.3333C7.33333 15.5101 7.40357 15.6797 7.5286 15.8047C7.65362 15.9298 7.82319 16 8 16C8.17681 16 8.34638 15.9298 8.47141 15.8047C8.59643 15.6797 8.66667 15.5101 8.66667 15.3333V8.66667H15.3333C15.5101 8.66667 15.6797 8.59643 15.8047 8.47141C15.9298 8.34638 16 8.17681 16 8C16 7.82319 15.9298 7.65362 15.8047 7.5286C15.6797 7.40357 15.5101 7.33333 15.3333 7.33333Z" fill="#3EA748"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_113_4747">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
        <ModalCategoryCrm
            v-show="isModalVisible"
            @close="closeModal" />
    </div>

</template>

<script>
import ModalCategoryCrm from "@/components/ModalCategoryCrm.vue";
import axios from "axios";
import simplebar from "simplebar-vue";
import 'simplebar-vue/dist/simplebar.min.css';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Fields",
    components: {
        ModalCategoryCrm,
        simplebar
    },
    data () {
        return {
            isModalVisible: false,
            getId: localStorage.getItem('id'),
            pages: [],
            pageCount: 0,
            pageNameNumber: 1,
            ulVisible: false
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true
        },
        closeModal() {
            this.isModalVisible = false
        },
        pageNext: function (num) {
            this.pageCount = num
            this.pageNameNumber = num + +1
            this.ulVisible = false
        },
        upSelect(indexPage, item, field) {
            //let num = Number(this.pages[indexPage].length - 1)
            //if ((item !== 0) && (item !== num)) {
            if (item !== 0) {
                let indexToMove = item
                let elementToMove = field
                this.pages[indexPage].splice(indexToMove, 1)
                this.pages[indexPage].splice((item - 1), 0, elementToMove)
            } else {
                alert ('index = ' + item) // конечная, выходим
            }
            console.log(this.pages[indexPage])
        },
        onDel(indexPage, item) {
            this.pages[indexPage].splice(item, 1)
            //console.log(this.pages[indexPage])
        }
    },
    mounted() {
        if (this.getId > 0) {
            axios
                .get("https://sibteh.space/form_constructor_demo/api/form/load/?id=" + this.getId)
                .then((response) => {
                    //this.form = response.data.result
                    if (response.data.result.pages.length !== 0) {
                        this.pages = response.data.result.pages
                    }
                    console.log(response.data.result.pages)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }
}
</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}
.fade-enter-active {
    transition-delay: .3s;
}

.paginav-block {
    position: absolute;
    z-index: 100;
    right: 0;
    top: -58px;
    width: 158px;

    & .page-active {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        color: rgba(101, 101, 101, 1);
        transition: all 0.4s ease;

        &:hover {
            color: rgba(62, 167, 72, 1);
        }
    }

    & .page-ul {
        display: none;
        background: #fff;
        border-radius: 10px;
        margin-top: 8px;
        transition: all 0.4s ease;

        //border: 1px solid red;

        & .page-li {
            cursor: pointer;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 16px;
            color: rgba(101, 101, 101, 1);
            background: #fff;
            border-bottom: 1px solid #f6f6f6;
            transition: all 0.4s ease;

            &:first-child {
                border-radius: 10px 10px 0 0;
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
            }

            &:hover {
                background: rgba(73, 203, 86, 0.2);
                color: rgba(62, 167, 72, 1);
            }
        }
    }
}

.ul-active {
    display: block !important;
    display: flex !important;
    flex-direction: column !important;
}
.li-active {
    background: rgba(73, 203, 86, 0.2) !important;
    color: rgba(62, 167, 72, 1) !important;
}
.link-active {
    transition: all 0.4s ease;
    color: rgba(62, 167, 72, 1) !important;
}
.svg-rotate {
    transition: all 0.2s ease;
    transform: rotate(-180deg);
}



.fields-list {
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-height: 489px;
    position: relative;
    overflow-y: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}





















.box-field {
    display: flex;
    flex-direction: column;

    &:first-child {
        margin-top: -20px;
    }

    & .top-field {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: rgba(101, 101, 101, 1);
    }
}
.field-block2 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    //justify-content: space-between;
    gap: 8px;
}
.field-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    flex: 1;
    height: 56px;
    padding: 0 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-top: 4px;
    color: rgba(192, 192, 192, 1);
    background: #fff;
    border-radius: 10px;
}
.icon-set {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(101, 101, 101, 1);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        transition: all 0.4s ease;
        border: 1px solid rgba(62, 167, 72, 1);

        & .icon-path {
            transition: all 0.4s ease;
            fill: rgba(62, 167, 72, 1);
        }
    }
}
.icon-up {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(101, 101, 101, 1);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        transition: all 0.4s ease;
        border: 1px solid rgba(62, 167, 72, 1);

        & .icon-path {
            transition: all 0.4s ease;
            fill: rgba(62, 167, 72, 1);
        }
    }
}
.icon-del {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(101, 101, 101, 1);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        transition: all 0.4s ease;
        border: 1px solid rgba(62, 167, 72, 1);

        & .icon-path {
            transition: all 0.4s ease;
            fill: rgba(62, 167, 72, 1);
        }
    }

}


.add-area {
    display: flex;
    padding: 0 32px;
    align-items: center;
    gap: 10px;
    color: #3EA748;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    height: 56px;
    border: 1px dashed #3EA748;
//background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: #3EA748; stroke-width: 2; stroke-dasharray: 10 30'/></svg>");
    border-radius: 10px;
    cursor: pointer;
}
.add-area-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
}
</style>