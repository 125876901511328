<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal"
                 role="dialog"
                 aria-labelledby="modalTitle"
                 aria-describedby="modalDescription"
            >
                <div class="modal-tags">
                    <div class="box-title">Разделы CRM</div>
                    <div class="tags">
                        <span>Посещение мероприятий</span>
                        <span>Контакт</span>
                        <span>Сделка</span>
                        <span>Мероприятия</span>
                        <span>Юные исследователи – науки и техники</span>
                        <span>Посещение мероприятий</span>
                        <span>Инициативы приоритета 2030</span>
                    </div>
                </div>

                <div class="modal-fields">
                    <div class="box-title">Поля</div>
                    <div class="page-search">
                        <input type="text" class="page" placeholder="Поиск по разделам CRM">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3138 12.0596H12.6555L12.4221 11.8346C13.4221 10.668 13.9388 9.07631 13.6555 7.38464C13.2638 5.06798 11.3305 3.21798 8.99713 2.93464C5.47213 2.50131 2.50546 5.46798 2.93879 8.99298C3.22213 11.3263 5.07213 13.2596 7.38879 13.6513C9.08046 13.9346 10.6721 13.418 11.8388 12.418L12.0638 12.6513V13.3096L15.6055 16.8513C15.9471 17.193 16.5055 17.193 16.8471 16.8513C17.1888 16.5096 17.1888 15.9513 16.8471 15.6096L13.3138 12.0596ZM8.31379 12.0596C6.23879 12.0596 4.56379 10.3846 4.56379 8.30964C4.56379 6.23464 6.23879 4.55964 8.31379 4.55964C10.3888 4.55964 12.0638 6.23464 12.0638 8.30964C12.0638 10.3846 10.3888 12.0596 8.31379 12.0596Z" fill="#656565"/>
                        </svg>
                    </div>
                    <div class="modal-list">

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="g1">
                                <label for="g1">Название</label>
                            </div>
                            <div class="list-item-type">list</div>
                        </div>

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="g2">
                                <label for="g2">Название</label>
                            </div>
                            <div class="list-item-type">string</div>
                        </div>

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="g3">
                                <label for="g3">Название</label>
                            </div>
                            <div class="list-item-type">list</div>
                        </div>

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="g4">
                                <label for="g4">Название</label>
                            </div>
                            <div class="list-item-type">string</div>
                        </div>

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="hg1">
                                <label for="hg1">Название</label>
                            </div>
                            <div class="list-item-type">list</div>
                        </div>

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="hg2">
                                <label for="hg2">Название</label>
                            </div>
                            <div class="list-item-type">string</div>
                        </div>

                        <div class="modal-list__item">
                            <div class="list-item-checkbox">
                                <input type="checkbox" name="" id="hg3">
                                <label for="hg3">Название</label>
                            </div>
                            <div class="list-item-type">list</div>
                        </div>

<!--                        <div class="modal-list__item">-->
<!--                            <div class="list-item-checkbox">-->
<!--                                <input type="checkbox" name="" id="hg4">-->
<!--                                <label for="hg4">Название</label>-->
<!--                            </div>-->
<!--                            <div class="list-item-type">string</div>-->
<!--                        </div>-->

<!--                        <div class="modal-list__item">-->
<!--                            <div class="list-item-checkbox">-->
<!--                                <input type="checkbox" name="" id="hrg4">-->
<!--                                <label for="hrg4">Название</label>-->
<!--                            </div>-->
<!--                            <div class="list-item-type">string</div>-->
<!--                        </div>-->

                    </div>
                </div>

                <div class="modal-btn">
                    <slot>
                        <button
                            type="button"
                            class="btn-close"
                            @click="close"
                            aria-label="Close modal"
                        >
                            Отмена
                        </button>
                    </slot>
                    <button class="btn-add-field">Добавить поля</button>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ModalCategoryCrm',
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped>
.modal-btn {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 20px;

    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        background: transparent;
    }

    & .btn-close {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: rgba(62, 167, 72, 1);
        border: 1px solid rgba(62, 167, 72, 1);
        border-radius: 10px;
        transition: all 0.4s ease;

        &:hover {
            color: #49CB56;
            border: 1px solid #49CB56;
        }
    }

    & .btn-add-field {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #fff;
        border: 1px solid rgba(62, 167, 72, 1);
        background: rgba(62, 167, 72, 1);
        border-radius: 10px;
        transition: all 0.4s ease;

        &:hover {
            background: #49CB56;
            border: 1px solid #49CB56;
        }
    }
}
.modal-list {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    position: relative;
    max-height: 440px;
    height: 314px;
    overflow-y: auto;

    & .list-item-type {
        padding: 12px 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: rgba(62, 167, 72, 1);
    }

    & .modal-list__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #c0c0c0;

        &:last-child {border: none}

        & .list-item-checkbox {
            padding: 12px 0;

            & label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #656565;
            }

            & {
                input[type="checkbox"]:checked,
                input[type="checkbox"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }

                input[type="checkbox"]:checked + label,
                input[type="checkbox"]:not(:checked) + label {
                    display: inline-block;
                    position: relative;
                    padding-left: 28px;
                    line-height: 20px;
                    cursor: pointer;
                }

                input[type="checkbox"]:checked + label:before,
                input[type="checkbox"]:not(:checked) + label::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    width: 16px;
                    height: 16px;
                    background-color: transparent;
                    border: 1px solid #656565;
                }

                input[type="checkbox"]:checked + label:before,
                input[type="checkbox"]:not(:checked) + label:before {
                    border-radius: 4px;
                }

                input[type="checkbox"]:checked + label:after,
                input[type="checkbox"]:not(:checked) + label:after {
                    content: "";
                    position: absolute;
                    -webkit-transition: all 0.2s ease;
                    -moz-transition: all 0.2s ease;
                    -o-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }

                input[type="checkbox"]:checked + label:after,
                input[type="checkbox"]:not(:checked) + label:after {
                    left: 1px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    width: 14px;
                    height: 14px;
                    background: url("../img/check2.svg") 50% 50% no-repeat;
                }

                input[type="checkbox"]:not(:checked) + label:after {
                    opacity: 0;
                }

                input[type="checkbox"]:checked + label:after {
                    opacity: 1;
                }
            }
        }
    }
}

.modal-fields {

    & .box-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: rgba(33, 33, 33, 1);
        margin: 26px 0 24px 0;
    }
}
.modal-tags {
    display: flex;
    width: 100%;
    flex-direction: column;

    & .box-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: rgba(33, 33, 33, 1);
    }

    & .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 14px;

        & span {
            display: flex;
            height: 24px;
            width: fit-content;
            padding: 0 12px;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: rgba(101, 101, 101, 1);
            background: #f6f6f6;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.4s ease;

            &:hover {
                color: rgba(62, 167, 72, 1);
                background: rgba(73, 203, 86, 0.20);
            }
        }
    }
}
.page-search {
    position: relative;

    & svg {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 16px auto 0;
        cursor: pointer;
    }

    & input[type="text"].page {
        display: flex;
        padding: 0 16px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: rgba(192, 192, 192, 1);
        background: #f6f6f6;
        border-radius: 10px;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(33, 33, 33, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal {
    background: #fff;
    width: 567px;
    min-height: 200px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 24px;
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease
}
</style>