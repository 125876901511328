<template>
  <div class="container">
      <div class="sidebar-left">
          <div class="sidebar">
              <svg width="127" height="32" viewBox="0 0 127 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_93_10838)">
                      <path d="M29.8585 14.19L24.8973 12.4213L19.934 14.19L14.9728 12.4213L10.0094 14.19L5.04815 12.4213L0.0869141 14.19V20.3722L5.04815 22.1429L10.0094 20.3722L14.9728 22.1429L19.934 20.3722L24.8973 22.1429L29.8585 20.3722V14.19Z" fill="#212121"/>
                      <path d="M29.8585 4.46858L24.8973 2.69983L19.934 4.46858L14.9728 2.69983L10.0094 4.46858L5.04815 2.69983L0.0869141 4.46858V10.6528L5.04815 12.4215L10.0094 10.6528L14.9728 12.4215L19.934 10.6528L24.8973 12.4215L29.8585 10.6528V4.46858Z" fill="#28BE46"/>
                      <path d="M10.0091 4.46858L5.04569 6.23944L0.0844727 4.46858L5.04569 2.69983L10.0091 4.46858Z" fill="#212121"/>
                      <path d="M19.9316 4.46858L14.9705 6.23944L10.0093 4.46858L14.9705 2.69983L19.9316 4.46858Z" fill="#212121"/>
                      <path d="M29.8566 4.46858L24.8955 6.23944L19.9321 4.46858L24.8955 2.69983L29.8566 4.46858Z" fill="#212121"/>
                      <path d="M0.0844727 20.3726L5.04569 18.6039L10.0091 20.3726L5.04569 22.1435L0.0844727 20.3726Z" fill="#28BE46"/>
                      <path d="M19.9336 20.3726L24.8947 18.6039L29.8581 20.3726L24.8947 22.1435L19.9336 20.3726Z" fill="#28BE46"/>
                      <path d="M19.9316 14.1903L14.9705 15.959L10.0093 14.1903V20.3725L14.9705 22.1434L19.9316 20.3725V14.1903Z" fill="#28BE46"/>
                      <path d="M29.8585 23.9119L24.8973 22.1432L19.934 23.9119L14.9728 22.1432L10.0094 23.9119L5.04815 22.1432L0.0869141 23.9119V30.0939L5.04815 31.8627L10.0094 30.0939L14.9728 31.8627L19.934 30.0939L24.8973 31.8627L29.8585 30.0939V23.9119Z" fill="#212121"/>
                      <path d="M0.0844727 30.0942L5.04569 28.3254L10.0091 30.0942L5.04569 31.8629L0.0844727 30.0942Z" fill="#28BE46"/>
                      <path d="M19.9336 30.0942L24.8947 28.3254L29.8581 30.0942L24.8947 31.8629L19.9336 30.0942Z" fill="#28BE46"/>
                      <path d="M19.9316 23.9119L14.9705 25.6806L10.0093 23.9119V30.0939L14.9705 31.8628L19.9316 30.0939V23.9119Z" fill="#28BE46"/>
                      <path d="M41.3159 3.32007H50.3091V5.3446H46.9203V13.2895H44.6861V5.3446H41.3159V3.32007Z" fill="#212121"/>
                      <path d="M57.3277 3.17358C60.4074 3.17358 62.694 5.36225 62.694 8.30237C62.694 11.2425 60.4074 13.4312 57.3277 13.4312C54.248 13.4312 51.9429 11.2425 51.9429 8.30237C51.9429 5.36225 54.2257 3.17358 57.3277 3.17358ZM60.3926 8.30237C60.3815 7.7093 60.1918 7.13264 59.8473 6.64485C59.5028 6.15705 59.0187 5.77986 58.456 5.56067C57.8934 5.34148 57.277 5.29006 56.6847 5.41289C56.0923 5.53571 55.55 5.82728 55.1263 6.25097C54.7024 6.67465 54.4159 7.2116 54.3027 7.79431C54.1894 8.377 54.2546 8.97949 54.4898 9.52607C54.725 10.0727 55.12 10.539 55.6248 10.8664C56.1297 11.1939 56.7223 11.3679 57.3277 11.3665C57.736 11.3691 58.1407 11.2912 58.5174 11.1372C58.8942 10.9833 59.2355 10.7565 59.5208 10.4705C59.8061 10.1845 60.0298 9.84516 60.1783 9.47264C60.3268 9.10012 60.3971 8.7021 60.385 8.30237H60.3926Z" fill="#212121"/>
                      <path d="M65.8413 3.32007H68.2023L71.2111 6.81102L74.2164 3.32007H76.5474V13.2895H74.3803V6.06321L71.2111 9.84233L68.0273 6.09239V13.2895H65.8413V3.32007Z" fill="#212121"/>
                      <path d="M84.9707 5.26012C84.5535 5.24596 84.138 5.31423 83.7483 5.46085C83.3585 5.60745 83.0031 5.82939 82.7028 6.11345C82.4026 6.39754 82.1638 6.7379 82.0009 7.11426C81.8378 7.49064 81.7538 7.89533 81.7538 8.30419C81.7538 8.71306 81.8378 9.11773 82.0009 9.49411C82.1638 9.87049 82.4026 10.2108 82.7028 10.4949C83.0031 10.779 83.3585 11.0009 83.7483 11.1475C84.138 11.2942 84.5535 11.3624 84.9707 11.3483C85.5057 11.3502 86.0325 11.2211 86.5035 10.9726C86.9743 10.7242 87.3743 10.3642 87.6669 9.92562L89.2682 11.279C88.7936 11.9654 88.1492 12.5228 87.3951 12.8991C86.6409 13.2755 85.8017 13.4585 84.9559 13.4312C81.9171 13.4312 79.5562 11.3775 79.5562 8.30237C79.5562 5.22729 82.1108 3.17358 84.941 3.17358C87.3467 3.17358 88.7097 4.42476 89.2682 5.32943L87.6669 6.6791C87.3739 6.24118 86.9737 5.88197 86.5029 5.63415C86.0319 5.38632 85.5053 5.25775 84.9707 5.26012Z" fill="#212121"/>
                      <path d="M92.1436 13.2895V3.32007H94.378V13.2895H92.1436ZM98.0683 3.32007H100.548L96.9847 7.65729L101.193 13.2895H98.5524L94.3891 7.74485L98.0683 3.32007Z" fill="#212121"/>
                      <path d="M105.974 9.97L110.726 3.32007H112.961V13.2895H110.726V6.5958L105.96 13.2895H103.725V3.32007H105.937L105.974 9.97Z" fill="#212121"/>
                      <path d="M119.046 9.96952L123.797 3.31959H126.032V13.289H123.797V6.59531L119.031 13.289H116.796V3.31959H119.031L119.046 9.96952ZM120.513 0.120483C120.5 0.245506 120.512 0.371878 120.551 0.491847C120.589 0.611816 120.652 0.722851 120.736 0.818147C120.819 0.913443 120.922 0.990989 121.037 1.04603C121.153 1.10107 121.278 1.13244 121.407 1.13822C121.535 1.13131 121.661 1.09932 121.776 1.04412C121.892 0.98893 121.995 0.911645 122.08 0.816816C122.164 0.721989 122.229 0.611537 122.269 0.491952C122.31 0.372367 122.325 0.246068 122.315 0.120483H123.943C123.943 1.62702 122.825 2.54626 121.407 2.54626C119.988 2.54626 118.856 1.62702 118.856 0.120483H120.513Z" fill="#212121"/>
                      <path d="M51.7422 31.164H49.5079V23.2191H44.8307V31.164H42.5962V21.1946H51.7422V31.164Z" fill="#212121"/>
                      <path d="M60.0471 21.0475C63.1268 21.0475 65.4171 23.2363 65.4171 26.1764C65.4171 29.1165 63.1268 31.3051 60.0471 31.3051C56.9674 31.3051 54.666 29.1165 54.666 26.1764C54.666 23.2363 56.9563 21.0475 60.0471 21.0475ZM63.1119 26.1764C63.1046 25.5824 62.9179 25.0038 62.5756 24.5138C62.2333 24.0235 61.7506 23.6434 61.1881 23.4215C60.6256 23.1994 60.0089 23.1455 59.4151 23.2663C58.8214 23.3872 58.2775 23.6773 57.8518 24.1003C57.4262 24.5233 57.1379 25.0603 57.0232 25.6435C56.9084 26.2266 56.9726 26.8302 57.2072 27.3779C57.442 27.9255 57.8368 28.3927 58.3422 28.7211C58.8475 29.0492 59.4409 29.2236 60.0471 29.2222C60.4546 29.2263 60.8586 29.1499 61.2352 28.9978C61.612 28.8456 61.9533 28.6206 62.2394 28.3363C62.5253 28.0522 62.75 27.7143 62.8999 27.3433C63.05 26.9721 63.122 26.5753 63.1119 26.1764Z" fill="#212121"/>
                      <path d="M67.0601 31.237V29.2417H67.6336C68.5273 29.2417 68.9815 28.8257 68.9815 27.6621V21.1946H76.9024V31.164H74.6681V23.2046H71.1079V27.7825C71.1079 29.8509 70.1845 31.2298 67.8682 31.2298L67.0601 31.237Z" fill="#212121"/>
                      <path d="M82.9899 27.8446L87.7416 21.1946H89.9759V31.164H87.7416V24.4704L83.0233 31.164H80.7891V21.1946H83.0233L82.9899 27.8446Z" fill="#212121"/>
                      <path d="M92.8928 21.1946H101.882V23.2191H98.4936V31.164H96.2592V23.2191H92.8853L92.8928 21.1946Z" fill="#212121"/>
                      <path d="M104.802 21.1946H112.518V23.2046H107.047V25.1451H110.905V27.0859H107.047V29.154H112.577V31.164H104.802V21.1946Z" fill="#212121"/>
                      <path d="M120.159 24.5835L122.739 21.1946H125.294L121.537 26.1082L125.454 31.164H122.713L119.969 27.6877L117.243 31.164H114.677L118.595 26.1082L114.826 21.1946H117.537L120.159 24.5835Z" fill="#212121"/>
                  </g>
                  <defs>
                      <clipPath id="clip0_93_10838">
                          <rect width="126.118" height="32" fill="white"/>
                      </clipPath>
                  </defs>
              </svg>
          <h1>Конструктор форм</h1>
          <div class="copy-bottom">
              <span>Если возникла проблема, то можете написать <a href="https://t.me/sibteh_alyona_bot" target="_blank">Алёне из Сибтеха</a></span>
              <span>С любовью, ваш <a href="https://sibteh.org/" target="_blank">Сибтех</a></span>
          </div>
          </div>
      </div>
      <div class="main">
          <div class="title-block">
              <div class="title">Формы</div>
              <nav>
                  <router-link class="title-btn" to="/settings/" v-on:click="saveId(0)">
                      <span>Создать форму</span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_136_244)">
                              <path d="M15.3333 7.33333H8.66667V0.666667C8.66667 0.489856 8.59643 0.320286 8.47141 0.195262C8.34638 0.0702379 8.17681 0 8 0C7.82319 0 7.65362 0.0702379 7.5286 0.195262C7.40357 0.320286 7.33333 0.489856 7.33333 0.666667V7.33333H0.666667C0.489856 7.33333 0.320286 7.40357 0.195262 7.5286C0.0702379 7.65362 0 7.82319 0 8C0 8.17681 0.0702379 8.34638 0.195262 8.47141C0.320286 8.59643 0.489856 8.66667 0.666667 8.66667H7.33333V15.3333C7.33333 15.5101 7.40357 15.6797 7.5286 15.8047C7.65362 15.9298 7.82319 16 8 16C8.17681 16 8.34638 15.9298 8.47141 15.8047C8.59643 15.6797 8.66667 15.5101 8.66667 15.3333V8.66667H15.3333C15.5101 8.66667 15.6797 8.59643 15.8047 8.47141C15.9298 8.34638 16 8.17681 16 8C16 7.82319 15.9298 7.65362 15.8047 7.5286C15.6797 7.40357 15.5101 7.33333 15.3333 7.33333Z" fill="white"/>
                          </g>
                          <defs>
                              <clipPath id="clip0_136_244">
                                  <rect width="16" height="16" fill="white"/>
                              </clipPath>
                          </defs>
                      </svg>
                  </router-link>

              </nav>
          </div>
          <!--  -->
      <div class="blocks-wrap block-f">

          <simplebar data-simplebar-auto-hide="false" ref="simplebar" style="max-height: 720px;">
              <div class="block" v-for="form in forms" :key="form.id">
                  <div class="block__item" :key="form.id">
                      <div class="block-title">{{ form.title + ' id: ' + form.id }}</div>
                      <div class="block-author">Автор: {{ form.owner }}</div>
                      <div class="block-date">Дата создания: {{ form.create_date }}</div>
                  </div>
                  <div class="block__btn" :key="form.id">
                      <router-link to="/settings/">
                      <button class="set-form" :key="form.id" v-on:click="saveId(form.id)">Редактировать</button>
                      </router-link>
                      <button class="del-form" :key="form.id">
                          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="icons" clip-path="url(#clip0_185_2011)">
                                  <path class="i-del" id="Vector" d="M13.9999 3.16667H11.9333C11.7785 2.41428 11.3691 1.73823 10.7741 1.25248C10.179 0.766727 9.43472 0.50097 8.66659 0.5L7.33325 0.5C6.56512 0.50097 5.8208 0.766727 5.22575 1.25248C4.63071 1.73823 4.22132 2.41428 4.06659 3.16667H1.99992C1.82311 3.16667 1.65354 3.2369 1.52851 3.36193C1.40349 3.48695 1.33325 3.65652 1.33325 3.83333C1.33325 4.01014 1.40349 4.17971 1.52851 4.30474C1.65354 4.42976 1.82311 4.5 1.99992 4.5H2.66659V13.1667C2.66764 14.0504 3.01917 14.8976 3.64407 15.5225C4.26896 16.1474 5.11619 16.4989 5.99992 16.5H9.99992C10.8836 16.4989 11.7309 16.1474 12.3558 15.5225C12.9807 14.8976 13.3322 14.0504 13.3333 13.1667V4.5H13.9999C14.1767 4.5 14.3463 4.42976 14.4713 4.30474C14.5963 4.17971 14.6666 4.01014 14.6666 3.83333C14.6666 3.65652 14.5963 3.48695 14.4713 3.36193C14.3463 3.2369 14.1767 3.16667 13.9999 3.16667ZM7.33325 1.83333H8.66659C9.0801 1.83384 9.48334 1.96225 9.82099 2.20096C10.1587 2.43967 10.4142 2.77699 10.5526 3.16667H5.44725C5.58564 2.77699 5.84119 2.43967 6.17884 2.20096C6.5165 1.96225 6.91974 1.83384 7.33325 1.83333ZM11.9999 13.1667C11.9999 13.6971 11.7892 14.2058 11.4141 14.5809C11.0391 14.956 10.5304 15.1667 9.99992 15.1667H5.99992C5.46949 15.1667 4.96078 14.956 4.58571 14.5809C4.21063 14.2058 3.99992 13.6971 3.99992 13.1667V4.5H11.9999V13.1667Z" fill="#656565"/>
                                  <path class="i-del" id="Vector_2" d="M6.66667 12.5003C6.84348 12.5003 7.01305 12.4301 7.13807 12.3051C7.2631 12.18 7.33333 12.0105 7.33333 11.8337V7.83366C7.33333 7.65685 7.2631 7.48728 7.13807 7.36225C7.01305 7.23723 6.84348 7.16699 6.66667 7.16699C6.48986 7.16699 6.32029 7.23723 6.19526 7.36225C6.07024 7.48728 6 7.65685 6 7.83366V11.8337C6 12.0105 6.07024 12.18 6.19526 12.3051C6.32029 12.4301 6.48986 12.5003 6.66667 12.5003Z" fill="#656565"/>
                                  <path class="i-del" id="Vector_3" d="M9.33366 12.5003C9.51047 12.5003 9.68004 12.4301 9.80506 12.3051C9.93009 12.18 10.0003 12.0105 10.0003 11.8337V7.83366C10.0003 7.65685 9.93009 7.48728 9.80506 7.36225C9.68004 7.23723 9.51047 7.16699 9.33366 7.16699C9.15685 7.16699 8.98728 7.23723 8.86225 7.36225C8.73723 7.48728 8.66699 7.65685 8.66699 7.83366V11.8337C8.66699 12.0105 8.73723 12.18 8.86225 12.3051C8.98728 12.4301 9.15685 12.5003 9.33366 12.5003Z" fill="#656565"/>
                              </g>
                              <defs>
                                  <clipPath id="clip0_185_2011">
                                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                                  </clipPath>
                              </defs>
                          </svg>
                      </button>
                  </div>
              </div>
          </simplebar>

      </div>
          <!--  -->
      </div>
  </div>

</template>

<script>
import axios from "axios"
import simplebar from "simplebar-vue";
import 'simplebar-vue/dist/simplebar.min.css';


let Home;
export default {
    name: Home,
    components: {
      simplebar
    },
    data () {
        return {
            forms: [],
            count: 0,
            dateForm: '',
        }
    },
    methods: {
        saveId(id) {
            localStorage.setItem('id', id)
        }
        // formDelete(id) {
        //     axios
        //         .get("https://sibteh.space/form_constructor_demo/api/form/delete/?id=" + id)
        //         .then((response) => {
        //             this.forms = response.data.result
        //             console.log(response.data.result)
        //         })
        // }
    },
    created() {
        axios
            .get("https://sibteh.space/form_constructor_demo/api/form/list/")
                .then((response) => {
                    this.forms = response.data.result
                    //console.log(response.data.result)
                })
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>

.ps {
    //height: 800px; /* or max-height: 400px; */
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 18px;
}
.ps__rail-y {
    display: block !important;
    opacity: 1 !important;
    //min-height: 100% !important;
    //background-color: red !important;
}

//.ps--active-x>.ps__rail-x, .ps--active-y>.ps__rail-y {
//    background-color: red !important;
//    min-height: 100% !important;
//    display: block !important;
//    opacity: 1 !important;
//}
//
//.ps__thumb-y {
//    min-height: auto !important;
//    display: block !important;
//    opacity: 1 !important;
//}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.sidebar-left {
  display: flex;
  flex-direction: column;
  min-width: 573px;
  height: 100%;
  padding: 64px 24px 64px 80px;
  border-radius: 0 60px 60px 0;
  background: #fff url("../img/main_bg.svg") right bottom no-repeat ;

    & .sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 64px;

      & h1 {
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & img {
          height: fit-content;
      }

      & .copy-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        color: #676767;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.64px;
        gap: 10px;

        & a {
          color: #3EA748;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
}
.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 120px 64px 48px;
}
.title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #212121;
}
.title-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0 32px;
    width: 215px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background: #3EA748;
    border-radius: 10px;
    transition: all 0.3s ease;

    &:hover {
        background: #49CB56;
    }
}
.blocks-wrap {
    display: flex;
    flex-direction: column;
    margin: 48px 0 0 0;
    gap: 16px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;

    /* ***************************************************************************** */
    //padding-right: 10px;
    //&::-webkit-scrollbar {
    //    width: 6px;               /* ширина scrollbar */
    //}
    //&::-webkit-scrollbar-track {
    //    background: #F6F6F6;        /* цвет дорожки */
    //}
    //&::-webkit-scrollbar-thumb {
    //    background-color: #f9f9f9;    /* цвет плашки */
    //}
    //
    //scrollbar-width: thin;
    //scrollbar-color: #d9d6d6 #F6F6F6;
    /* ***************************************************************************** */
}
.block {
    display: flex;
    justify-content: space-between;
    padding: 23px 32px;
    border-radius: 10px;
    background: #fff;
    transition: all 2s ease;
}
.block__item {
    display: flex;
    flex-direction: column;

    & .block-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #212121;
    }

    & .block-author {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #656565;
        margin: 8px 0 24px 0;
    }

    & .block-date {
        color: #ACACAC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.block__btn {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: nowrap;
}
.set-form {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #3EA748;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 24px;
    background: transparent;
    border: 1px solid #3EA748;
    border-radius: 10px;

    &:hover {
        transition: all 0.3s ease;
        color: #49CB56;
        border: 1px solid #49CB56;
    }
}
.del-form {
    color: #656565;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    padding: 0;
    background: transparent;
    border: 1px solid #656565;
    border-radius: 10px;

    &:hover {
        transition: all 0.3s ease;
        border: 1px solid #49CB56;

        & .i-del {
            transition: all 0.3s ease;
            fill: #3EA748;
        }
    }
}



</style>