<template>
    <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
    </transition>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style lang="scss">
@import './scss/_base-style.scss';
.fade-enter-active,
.fade-leave-active {
    transition: opacity .6s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>